import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image1 from '../../../Images/Banner1.png';
import image2 from '../../../Images/Banner2.png';
import image3 from '../../../Images/Banner3.png';


import './Banner.css';

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Slider {...settings}>
      <div>
        <img src={image1} alt="Image 1" className="your-image-class"/>
      </div>
      <div>
        <img src={image2} alt="Image 2" className="your-image-class" />
      </div>
      <div>
        <img src={image3} alt="Image 3" className="your-image-class"  />
      </div>
    </Slider>
  );
};

export default Banner;
