import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap'; // Import Dropdown
import { Link } from 'react-router-dom';
import useAuth from '../../../Hooks/useAuth';
import logo from '../../../Images/logo1.jpg';
import './Header.css';

const Header = () => {

    const { user, logout } = useAuth();

    return (


        <div className="head-bg">

            <Navbar className="navbar" collapseOnSelect expand="lg">

                <Container className="container-head">

                    <Navbar.Brand href="/home" style={{ marginLeft: '-30px' }}><img src={logo} /></Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" expand="lg" />

                    <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="ms-auto align-items-center">

                            <Link to="/home" className='list-item text-decoration-none'>Home</Link>

                            <Link to="/about" className='list-item text-decoration-none'>About</Link>

                            {/* Service Dropdown */}
                            <Dropdown>

                                <Dropdown.Toggle variant="link" id="service-dropdown" className='list-item text-decoration-none'>
                                    Treatment
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Root Canal Treatment & Restorations</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Tooth Extractions</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Dental Implants </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Dentures (Removable, Semi Fixed, Fixed)</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Crown and Bridges</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Smile Design and Smile Correction</Dropdown.Item>
                                   
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Full Mouth Rehabilitation</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Maxillofacial Prosthetics</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Temporomandibular Joint Treatment</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Orthodontic Treatment</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Aligners</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Geriatric and Pediatric Dental Care</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Cosmetic Treatments</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Aesthetic Dental and Facial Treatments</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Facial Fracture</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Botox / Derma Filler</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Jaw Cyst Removal</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Rhinoplasty (Nose Correction)</Dropdown.Item>
                                </Dropdown.Menu>


                            </Dropdown>



                            <Dropdown>
                                <Dropdown.Toggle variant="link" id="service-dropdown" className='list-item text-decoration-none'>
                                    Facilities
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Dental X-Ray </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/" className='text-decoration-none'>Medical store</Dropdown.Item>
                                   
                                </Dropdown.Menu>
                            </Dropdown>

                            <Link to="/contact" className='list-item text-decoration-none'>Contact</Link>
                            {/* {user.email 
                            ?
                            <button type="button" className="btn btn-danger" onClick={logout}>Log Out</button>
                            :
                            <Link to="/login" type="button" className="btn btn-danger">Login</Link>
                            }
                            {user.email &&
                                <Navbar.Text><FontAwesomeIcon icon={faUser} /><span className="userName">{user.displayName}</span></Navbar.Text>
                            } */}

                            <Link to="/Gallery" className='list-item text-decoration-none'>Gallery</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>


    );
};

export default Header;
