import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthProvider from './Context/AuthProvider';
import About from './Pages/Home/About/About.jsx';
import Approved from './Pages/Approved/Approved';
import Contact from './Pages/Contact/Contact/Contact';
import Dentist from './Pages/Dentist/Denitst/Dentist';
import Footer from './Pages/Home/Footer/Footer.jsx';
import Header from './Pages/Home/Header/Header.jsx';
import Home from './Pages/Home/Home/Home.jsx';
import Login from './Pages/Login/Login';
// import NotFound from './Pages/NotFound/NotFound';
import Service from './Pages/Services/Service/Service';
import Gallery from './components/Gallery';





function App() {
  
  return (
    
    <div className="App">
      
      <AuthProvider>
        <Router>
          <Header/>
          <Routes>
            <Route path='*' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/About' element={<About />} />
            <Route path='/service' element={<Service />} />
            <Route path='/dentist' element={<Dentist />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/approved' element={<Approved />} />
            <Route path='/login' element={<Login />} />
            <Route path='/Gallery' element={<Gallery />} />
            {/* <Route path='*' element={<NotFound />} /> */}

          </Routes>
         
          <Footer />
         
        </Router>
        
      </AuthProvider>


    </div>
  );
}

export default App;
