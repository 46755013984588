import React from 'react'

import Gal1 from '../Images/about1 (1).jpg'
import gal2 from '../Images/about1 (2).jpg'
import gal3 from '../Images/gal (1).jpg'
import gal4 from '../Images/gal (1).png'
import gal5 from '../Images/gal (2).jpg'
import gal6 from '../Images/gal (2).png'


import Gal7 from '../Images/gal (3).jpg'
import gal8 from '../Images/gal (4).jpg'
import gal9 from '../Images/gal (5).jpg'
import gal10 from '../Images/gal (6).jpg'


const Gallery = () => {
  return (
    <div>

      <br />

      <center><h1>Gallery</h1></center>


      <div className='gallery'>

        <div className='gallery2'>
          <img src={Gal1} />
        </div>

        <div className='gallery2'>
          <img src={gal2} />
        </div>
        <div className='gallery2'>
          <img src={gal4} />
        </div>



      </div>

      


      <div className='gallery'>

        <div className='gallery2'>
          <img src={gal5} />
        </div>
        <div className='gallery2'>
          <img src={gal6} />
        </div>

        <div className='gallery2'>
          <img src={gal10} />
        </div>
      </div>




      <div className='gallery'>

        <div className='gallery2'>
          <img src={gal8} />
        </div>
        <div className='gallery2'>
          <img src={gal9} />
        </div>
        

      </div>



    </div>
  )
}

export default Gallery