import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import donto from '../../../Images/about1 (1).jpg';

import donto2 from '../../../Images/about1 (2).jpg';
// import dontoAnimated from '../../../Images/cleaner.png';

import './About.css';

const About = () => {

    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
        AOS.refresh();
    }, []);
    return (
        <section className="about-wrapper">
            <br /><br />



            <Container>
                <Row>
                    <Col md={12} lg={6}>
                        <div className="about-left">
                            <img src={donto} alt="donto" className="img-fluid donto" />

                        </div>
                        <br />
                        <div className="about-left">
                            <img src={donto2} alt="donto" className="img-fluid donto" />

                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="about-right mt-5 mt-lg-0">
                            <div className="about-content text-start" data-aos="zoom-in">
                                <h1>Welcome to Shri Surya's Dental Care </h1>

                                <h2>Dr. Abhishek Singh </h2>
                                <h2>BDS , MDS, (PROSTHODONTIST).</h2>
                                <p>Currently Dr. Abhishek is associated with Sardar Patel Post Graduate Institute Of Dental and Medical Sciences  as Assistant Professor  since Jan.2021.</p>
                                <p>He Started his clinic with all advanced facilities & services from August -2023.</p>

                                <br />
                                <h2>Dr Mansi singh </h2>
                                <h2>BDS (LUCKNOW)</h2>
                            </div>



                            <div className="fun-fact-sec" data-aos="fade-right">
                                <div className="single-fun">
                                    <span>100</span>
                                    <span>+</span>
                                    <p>Happy Patients</p>
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-right">
                                    <span></span>
                                    <span>Contact Details </span>
                                    <p>07985091716 ,  09918270131.</p>
                                </div>
                                <div className="single-fun" data-aos="fade-left">
                                    {/* <span>08</span>
                                    <span>+</span>
                                    <p>Years Experience</p> */}
                                </div>
                                <div className="single-fun sp-fun" data-aos="fade-left">
                                    {/* <span>50</span>
                                    <span>+</span>
                                    <p>Dental Awards</p> */}
                                </div>
                                <span className="line"></span>
                            </div>
                        </div>


                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;