import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './ContactUs.css';

const ContactUs = () => {
    return (
        <>

            <br />

            <center><h1>Contact Us </h1></center>


            <section className="contactUs-wrapper">
                <Container>
                    <Row>
                        <Col md={6} lg={6} xl={4}>
                            <div className="single-contact icon1">
                                <div className="c-icon">
                                    <FontAwesomeIcon icon={faHome} />
                                </div>
                                <div className="c-info text-start">
                                    <h4>Address</h4>

                                    <p>Janraj Bhawan, Prithvi Nagar</p>
                                    <p> near Shaheed Path, Bijnor </p>
                                    <p> Rd,
                                        Aurangabad Jagir, Lucknow, Uttar Pradesh 226002</p>
                                </div>
                            </div>



                        </Col>
                        <Col md={6} lg={6} xl={4}>
                            <div className="single-contact icon2">
                                <div className="c-icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <div className="c-info text-start">
                                    <h4>Email</h4>
                                    <p style={{ fontSize: '12px' }}>    shrisuryadental@gmail.com</p>

                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={6} xl={4}>
                            <div className="single-contact icon3">
                                <div className="c-icon">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>
                                <div className="c-info text-start">
                                    <h4>Phone</h4>
                                    <p>+91 7985091716  09918270131</p>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
};

export default ContactUs;