import React from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import './Footer.css'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer-bg">
            <Container>
                <Row className="text-white">
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Useful Link</h2>
                            </div>
                            <div className="widget-content">

                                <NavLink className="footer-link" as={Link} to="/" >Home</NavLink>
                                <NavLink className="footer-link" as={Link} to="/about" >About</NavLink>
                                <NavLink className="footer-link" as={Link} to="/contact">Contact Us</NavLink>
                                <NavLink className="footer-link" as={Link} to="/Gallery" >Gallery</NavLink>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Department</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">Root Canal Treatment & Restorations</NavLink>
                                <NavLink className="footer-link">Tooth Extractions</NavLink>
                                <NavLink className="footer-link">Dental Implants </NavLink>
                                <NavLink className="footer-link">Fixed Crown and Bridges</NavLink>
                                <NavLink className="footer-link">Smile Design and Smile Correction</NavLink>
                               

                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Facilities</h2>
                            </div>
                            <div className="widget-content">
                                
                                <NavLink className="footer-link">Dental X-Ray </NavLink>
                                <NavLink className="footer-link">Medical Store</NavLink>
                               
                               
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Our Address</h2>
                            </div>
                            <div className="widget-content" >
                                <NavLink className="footer-link">Janraj Bhawan, Prithvi Nagar near Shaheed Path, Bijnor Rd,</NavLink>
                                <NavLink className="footer-link"> Aurangabad Jagir, Lucknow, Uttar Pradesh 226002</NavLink>
                                <NavLink className="footer-link">Phone: 07985091716 ,  09918270131. </NavLink>
                                <NavLink className="footer-link" >Email: shrisuryadental@gmail.com</NavLink>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer-copy-right text-center text-white">
                <p className='mb-0'>&copy; 2024 - <span className="developer">All Rights Reserved</span> | and Developed by  Creative Digital World</p>
            </div>
        </div>
    );
};

export default Footer;