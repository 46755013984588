export const FakeBlog = [
    {
        "id": "001",
        "title":"DENTAL",
        "title": " DENTAL     Dr. Neeta Suryavanshi B.D.S (Dental Surgeon) OPD Timings- Sunday to Friday – 11am to 2pm ",
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"
    
    },
    {
        "id": "002",
        "title": "  GASTRO   Dr. Anurag Mishra DM (Gastro) ON CALL",
       
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"
    },
    {
        "id": "003",
        "title": " GENRAL PHYSICIAN  Dr. S. Yadav M.D Physician OPD Timing-  5:30 pm to 8 pm ",
    
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"
    },
    {
        "id": "003",
        "title": " RADIOLOGIST Dr. Ajay Kumar Verma M.B.B.S (Radiologist) D.M.R.D , Ex. CMO  OPD Timing –  11am to 4 pm ",
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"
    },

    {
        "id": "003",
        "title": " PATHOLOGIST Dr. Siddhartha Gangwar M.D. (Pathologist)",
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"
    },
    {
        "id": "003",
        "title": " PLASTIC SURGEON  Dr. Pushpendra Kanujiya M.B.B.S , M.S., M.C.H ON CALL",
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"  
    },
    {
        "id": "003",
        "title": " NEUROLOGY  Dr.A Kumar M.B.B.S , M.S., M.C.H. ON CALL",
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"  
    },
    {
        "id": "003",
        "title": "NEPHROLOGIST Dr. Col. Kuldeep Singh  M.B.B.S , D.N.B ",
        "img": "https://i.ibb.co/9hNpRkD/blog2.jpg"  
    }
]