export const FakeService = [
    {
        "id": "001",
        "title": "Scaling and Cleaning ",
        "description": "Dental scaling is a type of cleaning that uses a special tool to reach plaque and tartar below the gum line. The roots of the teeth are often planed following the scaling, which is good for gum health.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "002",
        "title": "Root Canal Treatment & Restorations",
        "description": "The definitive restoration should be placed as soon as possible after completion of root canal treatment. ",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "003",
        "title": "Tooth Extractions",
        "description": "Not necessarily. While the extraction may hurt if you are under the effects of nitrous oxide, you should not be in excruciating pain.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "004",
        "title": "Dental Implants ",
        "description": "Dental implants are medical devices surgically implanted into the jaw to restore a person's ability to chew or their appearance.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "005",
        "title": "Dentures (Removable, Semi Fixed, Fixed)",
        "description": "There are two types of implant-supported dentures - Semi-fixed dentures and fixed dentures. Semi-fixes are the types of dentures that are held in the place of dental implants and are retained in place with the with the help of attachments ",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "006",
        "title": "Crown and  Bridges",
        "description": "While a crown is used to cover an existing tooth, a dental bridge is used to fill in the missing space caused by one or more lost teeth. ",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "007",
        "title": "Smile Design and Smile Correction",
        "description": "A smile design is essentially a smile makeover that artistically creates straighter, whiter, and aesthetically pleasing smiles.",
        "link": "Read More",
        "img": ""
    },

    
    {
        "id": "009",
        "title": "Full Mouth Rehabilitation",
        "description": "As the name implies, full mouth reconstruction refers to rebuilding and/or replacing all of the teeth in a patient's mouth. Full mouth reconstructions combine esthetics with the science of restorative dentistry to improve the health,  ",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "0010",
        "title": "Maxillofacial Prosthetics",
        "description": "In 1953, Ackerman defined maxillofacial prostheses as the phase of dentistry that repairs and artificially replaces parts of the face after injuries or surgical intervention.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "0010",
        "title": "Temporomandibular Joint ",
        "description": "TMJ dysfunction (TMD) causes pain and tenderness in your jaw joints and surrounding muscles and ligaments. Causes include teeth grinding, jaw injuries, arthritis and everyday wear and tear. ",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "0010",
        "title": "Orthodontic Treatment",
        "description": "Orthodontics is a dental specialty focused on aligning your bite and straightening your teeth. You might need to see an orthodontist if you have crooked, overlapped, twisted or gapped teeth.",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "Aligners",
        "description": "Clear aligners are nearly invisible plastic shells that snap over your teeth. As you wear them, they apply gentle pressure on your teeth to gradually reposition them.",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "Geriatric and Pediatric Dental Care ",
        "description": " In summary, geriatric dentistry specializes in addressing the unique oral health needs of older adults, while pediatric dentistry is focused on children's dental care.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "0010",
        "title": "Cosmetic Treatments",
        "description": "Cosmetic procedures are performed to reshape structures of the body and to alter a person's appearance. ",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "Aesthetic Dental and Facial Treatments",
        "description": "Facial aesthetics in dentistry encompasses various procedures aimed at enhancing the overall appearance of the face and smile.",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "Facial Fracture",
        "description": "Facial fractures are broken bones in the face. This kind of a fracture can involve bones of the upper jaw, lower jaw, cheeks, and nose or eye sockets.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "0010",
        "title": " Botox / Dermal Fillers",
        "description": "Lines of expression typically require Botox, while lines that usually sit at rest work better with dermal fillers.",
        "link": "Read More",
        "img": ""
    },
    {
        "id": "0010",
        "title": "Jaw Cyst Removal",
        "description": "The treatment for jaw cyst involves several parts including surgery, root canal of the infected tooth, jaw bone reconstruction, dental rehabilitation and medical therapy. ",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "Rhinoplasty (Nose Correction)",
        "description": "Rhinoplasty can change the size, shape or proportions of the nose. It may be done to fix issues from an injury, correct a birth defect or improve some breathing problems. ",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "EarLobe , EyeLid Surgery",
        "description": "Tongue tie is when a tight band of tissue connects the underside of the tongue to the floor of the mouth, keeping it from moving freely. ",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "Lip Surgery",
        "description": "Lip surgery, also called cheiloplasty, is a surgical procedure that allows you to reshape or restore the shape of your lips or give them more definition and volume. ",
        "link": "Read More",
        "img": ""
    },

    {
        "id": "0010",
        "title": "Tonque Tie",
        "description": "There are two main causes of tongue-tie; either the frenum is too short and tight, or it did not move back down the tongue during development and is still attached to the tongue tip.",
        "link": "Read More",
        "img": ""
    },
]


