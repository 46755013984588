import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import client from '../../../Images/testi1.png';
import './Testimonial.css';

const Testimonial = () => {

    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
        AOS.refresh();
    }, []);
    return (
        <section className="testimonial-wrapper">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="section-title">
                            <h1>Testimonials</h1>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col md={6} lg={4} sm={12}>
                        <div className="client-box text-center" data-aos="fade-right">

                            <h3 className="mt-4">puja saha</h3>

                        </div>
                    </Col>
                    <Col md={6} lg={8} sm={12}>
                        <div className="review-item text-start" data-aos="zoom-out">
                            <h5>Awesome Work</h5>
                            <p>I got treated with an implant by Dr Abhishek, he explained me the treatment plan very nicely and had a painless surgical experience.</p>
                        </div>
                    </Col>

                </Row>


                <Row className="align-items-center">
                    <Col md={6} lg={4} sm={12}>
                        <div className="client-box text-center" data-aos="fade-right">

                            <h3 className="mt-4">RAJENDRA YADAV</h3>

                        </div>
                    </Col>
                    <Col md={6} lg={8} sm={12}>
                        <div className="review-item text-start" data-aos="zoom-out">
                            <h5>Awesome Work</h5>
                            <p>I am associated with Dr Abhisek since 2023. He is very polite in behaviour and a qualified Dr in his profession.</p>
                        </div>
                    </Col>

                </Row>









            </Container>
        </section>
    );
};

export default Testimonial;