export const FakeFeatures = [
    {
        "id": "001",
        "title": "Easy Appointment",
        "description": "Easy! Appointments is a highly customizable web application that allows your customers to book appointments with you via the web.",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature1.svg"
    },
    {
        "id": "002",
        "title": "Emergency Service",
        "description": "The emergency services are the public organizations whose job is to take quick action to deal with emergencies when they occur,",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature2.svg"
    },
    {
        "id": "003",
        "title": "24/7 Service",
        "description": "4x7 means 24 hours a day, 7 days a week and is used to describe a service, such as computer server monitoring, that is continuous, is always available day or night",
        "img": "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg"
    }
]


