import React from 'react'

const Location = () => {
  return (
    <div style={{ height: '400px' }}>

      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14249.35795601223!2d80.92115634924315!3d26.765446271790076!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfb3022baa255%3A0x9fb57df8ee320c8e!2sShri%20Surya&#39;s%20Dental%20Care%20and%20Implant%20Clinic!5e0!3m2!1sen!2sin!4v1711022327353!5m2!1sen!2sin" style={{ width: '100%', height: '400px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    </div>
  )
}

export default Location